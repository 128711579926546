<template>
  <div>

    <v-dialog
      v-model="dialog"
      width="680px"
      persistent
      overlay-color="transparent"
    >
      <FormChannelSubscriptionCompactContractsForm :id="contract_id" :values="values" :loaded_values="loaded_values" @update="updateContractForm" @close="closeDialog" />
    </v-dialog>

    <v-row class="mt-0">
      <v-col cols="12">
        <div>
          <span class="primary--text">{{$t('CHANNEL_SUBSCRIPTION_CONTRACT.TITLES')}}</span>
          <v-text-field
            name="form_fields"
            style="display:none;"
            :value="loaded_contracts && loaded_contracts.length > 0"
            :rules="[$rules.required]"
          />
        </div>
        <p class="grey--text"><small>{{$t('CHANNEL_SUBSCRIPTION_CONTRACT.TEXT')}}</small></p>

        <div class="mb-4 d-flex">
          <v-btn
            small
            rounded
            depressed
            class="mr-4"
            color="secondary black--text"
            @click="newContract"
          >
            <v-icon size="16" class="mr-2">mdi-plus</v-icon>
            {{$t('CHANNEL_SUBSCRIPTION_CONTRACT.ADD')}}
          </v-btn>

          <div :class="[((loaded_contracts.filter(v => v.status > 0) || []).length === 0 ? 'error--text' : 'grey--text text-medium'), 'mt-1']">
            {{$tc('CHANNEL_SUBSCRIPTION_CONTRACT.TITLE_ACTIVE_COUNT', (loaded_contracts.filter(v => v.status > 0) || []).length)}}
          </div>
        </div>

        <v-card outlined>
        <v-list class="pa-0 scroll height-max-30vh position-relative">

          <Loading :value="loading" />

          <template v-if="loaded_contracts && loaded_contracts.length > 0">
            <template v-for="(item, i) in loaded_contracts">
            <v-list-item
              :key="`cont${i}`"
              :class="[item.status === -1 ? 'muted' : '']"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <v-chip
                    x-small
                    color="red white--text"
                    v-if="item.status === -1"
                    class="mr-2"
                  >
                    {{ $t('CHANNEL_SUBSCRIPTION_CONTRACT.CANCELED') }}
                  </v-chip>

                  <v-chip
                    x-small
                    color="red white--text"
                    v-else-if="isFinished(item)"
                    class="mr-2"
                  >
                    {{ $t('CHANNEL_SUBSCRIPTION_CONTRACT.FINISHED') }}
                  </v-chip>

                  <v-icon class="mr-2" size="16">mdi-calendar-clock</v-icon>
                  <small v-if="item.no_limit_time">
                    {{ $t('CHANNEL_SUBSCRIPTION_CONTRACT.NO_DATE_LIMIT') }}
                  </small>
                  <small v-else-if="item.duration">
                    {{ item.duration }}
                    {{ $t('CHANNEL_SUBSCRIPTION_CONTRACT.DAYS_AFTER_FIRST_USE') }}
                  </small>
                  <small v-else>
                    <strong class="mr-4" v-if="new Date(item.end_date) > new Date() && !isFinished(item)">{{ $t('CHANNEL_SUBSCRIPTION_CONTRACT.WILL_FINISHED', {date: $moment(item.end_date).fromNow() }) }}</strong>
                    {{ new Date(item.start_date).toLocaleDateString() }}
                    <v-icon class="mr-2 ml-2" size="14">mdi-arrow-left-right</v-icon>
                    {{ new Date(item.end_date).toLocaleDateString() }}
                  </small>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-progress-linear
                    class="mt-2"
                    rounded
                    reverse
                    :width="50"
                    :height="16"
                    :value="item.no_limit_value ? 0 : ((+item.value - (+item.used > +item.commited ? +item.used : +item.commited)) / +item.value) * 100"
                    :color="
                      item.status === -1
                        ? 'red'
                        : item.no_limit_value
                        ? 'info'
                        : getColorFromValue(
                            ((+item.value - (+item.used > +item.commited ? +item.used : +item.commited)) / +item.value) * 100
                          )
                    "
                  >
                    <small class="text-capitalize">
                      <strong v-if="item.no_limit_value">
                        <v-icon>mdi-infinity</v-icon>
                      </strong>
                      <strong v-else>
                        {{ (+item.value - (+item.used > +item.commited ? +item.used : +item.commited)).toLocaleString() }} / {{ (+item.value).toLocaleString() }}
                        <span>{{ item.type === 'credit' ? $t('COMMON.CREDITS') : $t('COMMON.LOCALE_CURRENCY_SIGN') }}</span>
                      </strong>
                    </small>
                  </v-progress-linear>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <div class="text-small pt-2">{{$t('COMMON.LAST_UPDATE')}} {{ new Date(item.updated_at).toLocaleString() }}</div>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action />

              <v-list-item-action>
                <div class="">
                  <v-btn icon @click="removeContract(item.id)" :disabled="item.status !== 1">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn icon @click="editContract(item.id)" :disabled="item.status !== 1">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </div>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="`div${i}`" />
          </template>
          </template>
          <div v-else>
            <v-alert
              dense
              text
              type="warning"
            >
              {{$t('CHANNEL_SUBSCRIPTION.NO_CONTRACT_ERROR')}}
            </v-alert>

          </div>
        </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>

</template>

<script>

import FormChannelSubscriptionCompactContractsForm from './FormChannelSubscriptionCompact.contracts.form.vue'
import Loading from '@/components/Loading'

const _colors = [
  [244, 67, 54],
  [255, 87, 34],
  [255, 87, 34],
  [255, 152, 0],
  [255, 152, 0],
  [255, 193, 7],
  [255, 193, 7],
  [205, 220, 57],
  [205, 220, 57],
  [139, 195, 74],
  [139, 195, 74],
  [76, 175, 80],
  [76, 175, 80],
]

export default {
  name:"FormChannelSubscriptionCompactContracts",
  components: {
    FormChannelSubscriptionCompactContractsForm,
    Loading
  },
  data: () => ({
    dialog: false,
    valid: false,
    loading: false,
    use_xtramile_account: false,
    contract_id: undefined,
    loaded_contracts: [],
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    },
    loaded_values: {
      type: [Object, undefined],
      default: () => ({})
    }
  },
  watch: {
    methods (n,o) {
      n.filter(v => this.channel.methods.indexOf(v) !== -1)
      if (n && n.length !== o.length) {
        this.$emit('input', {methods: n})
      }
    },
    values: {
      handler () {
        this.attribValues()
      },
      deep: true,
    },
    loaded_values: {
      handler () {
        this.loadContractItems()
      },
      deep: true,
    },

  },
  created () {
    this.attribValues()
  },
  methods: {

    isFinished (item) {
      let about_time = item.no_limit_time ? false : new Date(item.end_date) < new Date()
      let about_value = item.no_limit_value ? false : item.type === 'credit' ? item.commited >= item.value : item.used >= item.value
      return about_time && about_value
    },

    getColorFromValue(value = 0) {
      const part = 100 / _colors.length
      const ratio = value / part
      const index = Math.floor(ratio)
      let color = _colors[index] || _colors[_colors.length - 1]
      return `rgb(${color[0]}, ${color[1]}, ${color[2]})`
    },

    updateContractForm (id) {
      this.loadContractItems()
      if (id) this.contract_id = id
    },

    removeContract(id) {
      if (id)
        return this.$services.api_programmatic.channel_subscription_contract
          .remove({ where: { id: id } })
          .then((response) => {
            this.loadContractItems()
          })
    },

    closeDialog () {
      this.dialog = false
    },

    newContract() {
      this.contract_id = undefined
      this.dialog = true
    },

    editContract(id) {
      this.contract_id = id
      this.dialog = true
    },

    save_start_date(date) {
      this.$refs.menu_start_date.save(date)
    },

    save_end_date(date) {
      this.$refs.menu_end_date.save(date)
    },


    loadContractItems() {
      this.loading = true
      if (this.loaded_values.id)
        return this.$services.api_programmatic.channel_subscription_contract
          .search({
            where: {channel_subscription_id: this.loaded_values.id},
          })
          .then(response => {
            if (response && response.data.length > 0) {
              this.loaded_contracts = response.data
            } else {
              this.loaded_contracts = []
            }
          })
          .finally(() => {
            this.loading = false
          })
    },

    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        this[key] = values[key]
      })
    },


  }
}
</script>
